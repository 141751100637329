import React from "react"
import { Flex, Box, Text, Stack } from "@sqymagma/elements"
import { Link } from "gatsby"
import Btn from "../components/button"

const NotFound = ({ tagid404bot, ...props }) => {
  return (
    <Flex
      bg="brand03"
      height="100vh"
      width="100%"
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      <Stack
        bg="bg01"
        height={["380px", "464px", "464px", "528px", "816px"]}
        width={["256px", "608px", "608px", "736px", "1324px"]}
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        textAlign="center"
        vs={["13px", "16px", "16px", "25px", "30px"]}
      >
        <Box
          textAlign="center"
          fontSize={["22px", "22px", "22px", "26px", "42px"]}
          fontFamily="Rubik, sans-serif"
          letterSpacing="-1px"
          fontWeight="500"
        >
          LivingAlternatives
        </Box>
        <Box
          fontSize={["80px", "130px", "145px", "150px", "245px"]}
          fontFamily="Rubik, sans-serif"
          color="brand03"
        >
          404
        </Box>
        <Box>
          <Text textStyle="h3" color="text01" width={["175px", "420px", "530px"]}>
            Página no encontrada
          </Text>

          <Text textStyle="p1" color="text03" width={["175px", "420px", "530px"]}>
            Lo siento, la página que buscas no existe.
          </Text>
        </Box>
        <Btn size="regular" width={["176px", "184px"]} height={["40px", null, null, null, "56px"]}>
          <Link to="/" id={tagid404bot}>
            Volver al inicio
          </Link>
        </Btn>
      </Stack>
    </Flex>
  )
}

export default NotFound
